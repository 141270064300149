<template>
  <!-- 直播容器 -->
  <div id="liveContainer">
    <iframe
      :src="`https://live.polyv.cn/watch/${liveRoomNumber}?hasFrame=1`"
      frameborder="0"
      allowfullscreen="true"
    ></iframe>
  </div>
</template>
<script>
export default {
  name: "livePage",
  data() {
    return {
      liveRoomNumber: this.$route.query.roomNumber,
    };
  },
};
</script>
<style lang="scss" scoped>
#liveContainer {
  position: fixed;
  margin-top: 54px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  iframe {
    width: 100%;
    height: 100%;
  }
}
</style>